import http from '@/shared/http'
import apiResultWrapper from '@/shared/apiResultWrapper'

const API = '/api/v1/monthly-statement'

// fetch log about monthly statement execution
const fetchMonthlyStatementExecution = async ({ page, size }) => apiResultWrapper.apiResult(await http.get(`${API}/execution-log?page=${page || 0}&size=${size || 25}`))

// upload new file and execute monthly statement
const uploadFileAndExecuteStatement = async (file, year, month) => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('year', year)
  formData.append('month', month)
  return apiResultWrapper.apiResult(await http.post(`${API}/execute`, formData, {
    headers: { 'Content-Type': 'multipart/form-data' }
  }))
}

const downloadAdminFile = async (executionLogId) => {
  return await http.get(`${API}/executions/${executionLogId}/download/account-admin-input-file`, { responseType: 'blob' })
}

const downloadReportFile = async (executionLogId) => {
  return await http.get(`${API}/executions/${executionLogId}/download/account-prop-report-file`, { responseType: 'blob' })
}

const fetchAccounts = async () => apiResultWrapper.apiResult(await http.get(`${API}/fetch-accounts`))

const fetchReport = async (account, date) => apiResultWrapper.apiResult(await http.get(`${API}/${account}/${date}/report`))

export default {
  fetchMonthlyStatementExecution,
  uploadFileAndExecuteStatement,
  downloadAdminFile,
  downloadReportFile,
  fetchAccounts,
  fetchReport
}
