<template>
  <v-container
    id="system-settings"
    fluid
    tag="section"
  >
    <v-row class="ma-5">
      <v-col cols="8">
        <v-file-input
          v-model="file"
          accept=".xlsx"
          label="Click to attach file *"
          :disabled="isLoading"
          counter
          show-size
          clearable
          class="pt-0"
        />
      </v-col>
      <v-col cols="2">
        <date-picker
          :value="selectedMonth"
          type="MONTHLY"
          label="Choose month"
          @change-date="(d) => this.selectedMonth = d"
          :disabled="isLoading"
        />
      </v-col>
      <v-col
        cols="2"
        class="text-right"
      >
        <v-btn
          color="primary"
          :disabled="isLoading || !file || !selectedMonth"
          @click="uploadFileAndGenerateMonthlyStatement()"
        >
          Generate Monthly Statements
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="[
            { text: 'Start Time', value: 'executionTimeStarted' },
            { text: 'Finish Time', value: 'executionTimeFinished' },
            { text: 'Start by', value: 'startedBy' },
            { text: 'For year', value: 'year' },
            { text: 'For month', value: 'month' },
            { text: 'Admin Filename', value: 'uploadAccountInputFilename' },
            { text: 'Admin File', value: 'uploadAccountInputFileId'},
            { text: 'Prop Report Filename', value: 'downloadPropReportFilename' },
            { text: 'Prop Report File', value: 'downloadPropReportFileId'}]"
          :items="executionLog"
          :items-per-page="25"
          :loading="isLoading"
          :disable="isLoading"
          hide-default-footer
          :disable-sort="false"
          loading-text="Loading... Please wait"
          class="elevation-1 row-pointer"
        >
          <template v-slot:item.executionTimeStarted="{item}">
            <span>{{new Date(item.executionTimeStarted).toLocaleString('en')}}</span>
          </template>

          <template v-slot:item.executionTimeFinished="{item}">
            <span>{{new Date(item.executionTimeFinished).toLocaleString('en')}}</span>
          </template>

          <template v-slot:item.uploadAccountInputFileId="{item}">
            <v-btn
              small
              :disabled="isLoading"
              color="secondary"
              @click="downloadFile(item.uploadAccountInputFilename, item.id, 'admin')">
              <v-icon class="mr-2">mdi-download</v-icon>
              Download File
            </v-btn>
          </template>
          <template v-slot:item.downloadPropReportFileId="{item}">
            <v-btn
              small
              :disabled="isLoading"
              color="secondary"
              @click="downloadFile(item.downloadPropReportFilename, item.id, 'report')">
              <v-icon class="mr-2">mdi-download</v-icon>
              Download File
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <v-row justify="end">
      <v-col cols="11" class="text-right">
        <v-pagination
          :disabled="isLoading"
          v-model="pagination.page"
          :length="pagination.totalPages"
          @input="fetchMonthlyStatementExecution()"
        ></v-pagination>
      </v-col>
      <v-col cols="1" class="text-right" align-self="end">
        <v-select
          :disabled="isLoading"
          class="pa-0 ma-0 mt-2"
          v-model="pagination.size"
          @change="changePageSize()"
          :items="[5, 10, 25, 50, 100]"
        ></v-select>
      </v-col>
    </v-row>
    <v-row>
      <div class="text-center">
        <v-dialog
          v-model="dialog.show"
          width="500"
        >
          <v-card>
            <v-card-title class="headline lighten-2">
              <span
                :class="`${dialog.error ? 'red--text' : 'blue--text'} text-uppercase`"
                style="letter-spacing: 0.3rem"
              > {{ dialog.title }} </span>
            </v-card-title>

            <v-card-text
              align="center"
            >
              {{ dialog.message }}
            </v-card-text>

            <v-divider />

            <v-card-actions>
              <v-spacer />
              <v-btn
                color="primary"
                text
                @click="dialog.show = false"
              >
                OK
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>
<script>
import MonthlyStatementService from './service/MonthlyStatementService'
import DatePicker from '@/components/DatePicker'

export default {
  name: 'MonthlyStatementAdmin',
  components: {
    DatePicker
  },
  data: () => ({
    file: null,
    date: '',
    isLoading: false,
    selectedMonth: '',
    executionLog: [],
    pagination: {
      page: 1,
      size: 25,
      totalPages: 1
    },
    dialog: {
      show: false,
      title: '',
      message: ''
    }
  }),
  mounted () {
    const today = new Date()
    today.setMonth(today.getMonth() - 1)
    this.date = `${today.getFullYear()}-${today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}`
    this.selectedMonth = `${today.getFullYear()}-${today.getMonth() < 9 ? '0' + (today.getMonth() + 1) : today.getMonth() + 1}`
    this.fetchMonthlyStatementExecution()
  },
  methods: {
    updateDate (date) {
      this.date = date
    },
    async downloadFile (fileName, executionLogId, type) {
      this.isLoading = true
      const response = type === 'admin'
        ? await MonthlyStatementService.downloadAdminFile(executionLogId)
        : await MonthlyStatementService.downloadReportFile(executionLogId)
      if (response.status === 200) {
        const blob = new Blob([response.data])
        const link = document.createElement('a')
        link.href = URL.createObjectURL(blob)
        link.download = fileName
        link.click()
        URL.revokeObjectURL(link.href)
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = `Cannot download ${fileName} file!`
      }
      this.isLoading = false
    },
    async changePageSize () {
      // this.pagination.page = 1
      await this.fetchMonthlyStatementExecution()
    },
    async fetchMonthlyStatementExecution () {
      this.isLoading = true
      const response = await MonthlyStatementService.fetchMonthlyStatementExecution({ page: this.pagination.page - 1, size: this.pagination.size })
      if (response.status === 200) {
        this.executionLog = response.data.content
        this.pagination.totalPages = response.data.totalPages
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot fetch Monthly statement executions log!'
      }
      this.isLoading = false
    },
    async uploadFileAndGenerateMonthlyStatement () {
      this.isLoading = true
      // const d = this.date.split('-')
      const d = this.selectedMonth.split('-')
      const response = await MonthlyStatementService.uploadFileAndExecuteStatement(this.file, d[0], Number(d[1]))
      if (response.status === 200) {
        this.dialog.show = true
        this.dialog.title = 'Success'
        this.dialog.error = false
        this.dialog.message = 'Monthly statements is successfully generated!'
      } else {
        this.dialog.show = true
        this.dialog.title = 'Error'
        this.dialog.error = true
        this.dialog.message = 'Cannot execute monthly statements! ' + response.error.message
      }

      this.isLoading = false
      await this.fetchMonthlyStatementExecution()
    }
  }
}
</script>
<style scoped>
.tab-header {
  font-weight: 400;
  letter-spacing: 0.3rem;
}
</style>
