<template>
  <v-dialog
    ref="d"
    v-model="dateModal"
    :return-value.sync="date"
    :disabled="disabled"
    persistent
    width="290px"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="date"
        class="mt-0 pt-0"
        :disabled="disabled"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker
      v-model="date"
      :max="maxDay"
      :type="type === 'MONTHLY' ? 'month' : 'day'"
      scrollable
    >
      <v-spacer />
      <v-btn
        text
        color="primary"
        @click="dateModal = false"
      >
        Cancel
      </v-btn>
      <v-btn
        text
        color="primary"
        @click="$refs.d.save(date) & changeDate()"
      >
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
</template>

<script>
export default {
  name: 'DatePicker',
  components: {
  },
  props: {
    type: {
      type: String,
      validated: (val) => ['daily', 'monthly'].indexOf(val) > -1
    },
    disabled: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Date for *'
    },
    value: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    dateModal: false,
    date: '' // this.$props.value
  }),
  computed: {
    maxDay: () => {
      return new Date().toISOString().substr(0, 10)
    }
  },
  mounted () {
    this.date = this.$props.value
  },
  methods: {
    changeDate () {
      this.$emit('change-date', this.date)
    }
  }
}
</script>

<style scoped>
</style>
